import Nav from "./Nav";
import useBreakpoint from "../hooks/useBreakpoint";
import classNames from "classnames";
import MTDesktopLogo from "../icons/mohawk_today_lockup_white_logo.svg";
import MTMobileLogo from "../icons/mohawk_today_white_logo.svg";
import fbIcon from "../icons/f_logo_RGB-White_1024.png";
import igIcon from "../icons/Instagram_Glyph_White.png";
import twIcon from "../icons/2021 Twitter logo - white.png";
import ttIcon from "../icons/TikTok-logo-RGB-Stacked-white-simplified.png";
import * as constants from "../constants";
import "./footer.css";

function Footer() {
  const isMobile = useBreakpoint(constants.mobileBreakpointQuery);

  return (
    <div className={classNames("mtt-footer", { "mtt-mobile": isMobile })}>
      <div className="mtt-footer-logo-container">
        <img
          src={isMobile ? MTMobileLogo : MTDesktopLogo}
          alt="MohawkToday Logo"
        />
      </div>
      <Nav isMobile={isMobile} />
      <div className="mtt-footer-more-info-container">
        <div className="mtt-footer-split-info-container">
          <div>
            <strong
              className={classNames({
                "mt-text-large": !isMobile,
                "mt-text-normal": isMobile,
              })}
            >
              Need Help?
            </strong>
            <div
              className={classNames("mtt-footer-contact", {
                "mt-text-small": isMobile,
              })}
            >
              <a href={constants.FAQUrl}>FAQs</a>
            </div>
            <div
              className={classNames("mtt-footer-contact", {
                "mt-text-small": isMobile,
              })}
            >
              {/* 800.241.4494
          <br /> */}
              MohawkToday@mohawkind.com
            </div>
          </div>
          <div className="mtt-footer-follow-container">
            <div
              className={classNames({
                "mt-text-normal": !isMobile,
                "mt-text-small": isMobile,
              })}
            >
              Follow Us
            </div>
            <div className="mtt-ffc-links">
              <a href="https://www.facebook.com/MohawkEdgePartners">
                <img src={fbIcon} alt="Facebook" />
              </a>
              <a href="https://www.instagram.com/mohawkedgepartners/">
                <img src={igIcon} alt="Instagram" />
              </a>
              <a
                href="https://twitter.com/MohawkEdge"
                className="mtt-ffc-link-smaller"
              >
                <img src={twIcon} alt="Twitter" />
              </a>
              <a href="https://www.tiktok.com/@mohawkedgepartners">
                <img src={ttIcon} alt="TikTok" />
              </a>
            </div>
          </div>
        </div>
        <div
          className={classNames("mtt-footer-legal", {
            "mt-text-extra-small": isMobile,
          })}
        >
          Copyright 2022 Mohawk Industries, INC. All Rights Reserved
        </div>
      </div>
    </div>
  );
}

export default Footer;
