import "./globals.js";
import React from "react";
import { createRoot } from "react-dom/client";
import ArticleLoader from "./ArticleLoader.js";
import Header from "./Header";
import Footer from "./Footer";
import axiosMaker from "axios";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as constants from "./constants";
import "./styles/css_groupname_selectors.css";
import "./styles/mt-plone-overrides.css";
import "./styles/mttheme.css";
// This generates a chunk in the build process so we default to commenting it out
// To use be sure web-vitals is installed ( npm i --save-dev web-vitals )
// import reportWebVitals from "./reportWebVitals";

const axios = axiosMaker.create();
axios.defaults.withCredentials = true;

function defaultQueryFn({ queryKey }) {
  return axios.get(queryKey[0]).then(res => res.data);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // cache for 5 minutes
      cacheTime: 1000 * 60 * 5,
      queryFn: defaultQueryFn,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      networkMode: "offlineFirst",
    },
    mutations: {
      networkMode: "offlineFirst",
    },
  },
});

queryClient.setMutationDefaults([constants.toggleShowDeadLinksMutationName], {
  mutationFn: showDeadLinks => {
    const updatedShowDeadLinks = !showDeadLinks;
    let endpoint = constants.setShowDeadLinksEndpoint;
    if (process.env.NODE_ENV === "development" && !constants.isPloneDev) {
      endpoint =
        updatedShowDeadLinks === true
          ? "data/user-admin-show.json"
          : "data/user-admin-hide.json";
    }
    return axios
      .get(endpoint, { params: { show_dead_links: updatedShowDeadLinks } })
      .then(res => res.data);
  },
  onMutate: async showDeadLinks => {
    // optimistically update user so UI updates immediately
    await queryClient.cancelQueries([constants.getUserEndpoint]);
    queryClient.setQueryData([constants.getUserEndpoint], old => ({
      ...old,
      show_dead_links: !showDeadLinks,
    }));
  },
  onSuccess: res => {
    queryClient.setQueryData([constants.getUserEndpoint], res);
  },
  // since this mutation is not vital to persist on the server,
  // we retry once on error but do nothing else for error handling
  retry: 1,
});

const headerRoot = createRoot(document.getElementById("mt-react-header"));

headerRoot.render(
  <React.StrictMode>
    <QueryClientProvider
      client={queryClient}
      // contextSharing allows multiple react query instances in different applications
      // on the same page to use the same context to allow deduping of requests
      // (e.g. the many useUser requests on the MT homepage)
      contextSharing={true}
    >
      <Header />
    </QueryClientProvider>
  </React.StrictMode>
);

const footerRoot = createRoot(document.getElementById("mt-react-footer"));

footerRoot.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Footer />
    </QueryClientProvider>
  </React.StrictMode>
);

// Load homepage article from json an when element with id of "homepage-article"
// has a "react-data-article-path" attribute.  Uses this attribute to set the url
// to load the article
const homepageArticleElement = document.getElementById("homepage-article");

if (homepageArticleElement) {
  const homepageArticleRoot = createRoot(homepageArticleElement);
  const articlePath = homepageArticleElement.getAttribute(
    "react-data-article-path"
  );
  if (articlePath) {
    homepageArticleRoot.render(<ArticleLoader articlePath={articlePath} />);
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// This might be useful for local dev sometime.  To use see above and uncomment below.
// reportWebVitals();

if (process.env.NODE_ENV === "development") {
  if (constants.isPloneDev) {
    console.log(
      "WEBPACK DEV SERVER INFO: Shell environment variable 'IS_PLONE' is set to 'true'. \nUsing plone dev webpack config.  To change this set 'IS_PLONE' to 'false'.\n"
    );
  } else {
    console.log(
      "WEBPACK DEV SERVER INFO: Using local dev webpack config, NOT configured for plone development.  \nTo change this set the shell environement variable 'IS_PLONE' to 'true'.\n"
    );
  }
}
