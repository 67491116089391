import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import NavMobile from "../SharedComponents/NavMobile";
import SearchAsYouType from "./SearchAsYouType";
import useNavProps from "../hooks/useNavProps";
import classNames from "classnames";
import * as constants from "../constants";
import * as h from "../helpers";

function NavMobileWrapper({ closeNav, edgeStatus, isOpen, user, nav }) {
  const searchBarRef = useRef();
  const wrapperRef = useRef();

  const { clearSelectedItems, ...restNavProps } = useNavProps(nav);

  const navProps = {
    clearSelectedItems,
    ...restNavProps,
    closeNav,
    isOpen,
    user,
  };

  // open and close nav effect BEGIN
  useEffect(() => {
    if (isOpen) {
      h.setPreventBodyScroll(true);
      h.slideBodyWithMenu(true, wrapperRef);
    } else {
      clearSelectedItems();
      h.setPreventBodyScroll(false);
      h.slideBodyWithMenu(false, wrapperRef);
    }

    // revert if component unmounts (as it does when no longer mobile)
    return () => {
      clearSelectedItems();
      h.setPreventBodyScroll(false);
      h.slideBodyWithMenu(false, wrapperRef);
    };
  }, [clearSelectedItems, isOpen]);
  // open and close nav effect END

  return (
    <>
      {createPortal(
        <>
          <div
            className={classNames("mtt-header-mobile-nav-backdrop", {
              "mtt-header-mobile-nav-backdrop-open": isOpen,
            })}
          ></div>
          <div
            className="mtt-header-mobile-nav-container"
            ref={wrapperRef}
            onClick={h.captureClick}
          >
            {isOpen
              ? createPortal(
                  <a
                    className="mtt-hmnc-close-x"
                    href="close-nav"
                    onClick={e => {
                      e.preventDefault();
                      closeNav();
                    }}
                    style={{
                      "--mtt-hmnc-close-x-scroll-offset-top":
                        window.scrollY + "px",
                    }}
                  >
                    X
                  </a>,
                  document.body
                )
              : null}
            <div className="mtt-hmnc-status-container">
              {edgeStatus ? (
                <a href={constants.edgeUrl} className="mt-icon-link">
                  <img
                    className="mtt-hmnc-sc-edge-status-icon"
                    src={edgeStatus.whiteIcon}
                    alt={`Edge ${edgeStatus.name} Icon`}
                  />
                </a>
              ) : (
                <div></div>
              )}
              {user?.dealer?.account_code ? (
                <span className="mtt-hmnc-sc-account-code">
                  Acct: {user.dealer.account_code}
                </span>
              ) : null}
            </div>
            <div className="mtt-hmnc-actions-container">
              <a
                className="button button-small mtt-hmnc-button"
                href={constants.FAQUrl}
              >
                FAQ
              </a>
              <div className="mtt-hmnc-search-bar" ref={searchBarRef}>
                <SearchAsYouType
                  fromMobileNav={true}
                  isMobile={true}
                  searchBarRef={searchBarRef}
                />
              </div>
            </div>
            <NavMobile {...navProps} />
          </div>
        </>,
        document.body
      )}
    </>
  );
}

export default NavMobileWrapper;
