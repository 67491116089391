import { useEffect, useRef } from "react";
import SearchAsYouTypeResult from "./SearchAsYouTypeResult";
import classNames from "classnames";

function SearchAsYouTypeResults({
  fromMobileNav,
  isMobile,
  keyboardHighlightIndex,
  moreResultsAvailable,
  searchBarRef,
  searchReturnedNoResults,
  searchResults,
  searchTermForCurrentResults,
  submitSearch,
}) {
  const resultsContainer = useRef();

  useEffect(() => {
    // scroll to bottom of results container (to reveal "Show All" button)
    // when last result is keyboard highlighted
    if (keyboardHighlightIndex === searchResults.length - 1) {
      resultsContainer.current.scrollTop += 100;
    }
  }, [keyboardHighlightIndex, searchResults]);

  return (
    <div
      className={classNames("mt-search-ayt-container-outer", {
        "mt-search-ayt-container-outer-mobile": isMobile,
        "mt-search-ayt-container-outer-from-mobile-nav": fromMobileNav,
      })}
      style={
        searchBarRef?.current
          ? {
              "--mt-search-ayt-search-bar-bottom":
                searchBarRef.current.getBoundingClientRect().bottom + "px",
            }
          : {}
      }
      ref={resultsContainer}
    >
      <div className="mt-search-ayt-container">
        {searchReturnedNoResults && (
          <div className="mt-search-ayt-no-matches-container">
            No matches found for{" "}
            <span className="mt-search-ayt-no-matches-term">
              "{searchTermForCurrentResults}"
            </span>
          </div>
        )}
        {!searchReturnedNoResults &&
          searchResults.map((res, index) => {
            return (
              <SearchAsYouTypeResult
                key={res.title + index}
                index={index}
                keyboardHighlightIndex={keyboardHighlightIndex}
                result={res}
              />
            );
          })}
        {moreResultsAvailable && (
          <div className="mt-search-ayt-show-all-container">
            <div className="button button-small" onClick={submitSearch}>
              Show All
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchAsYouTypeResults;
