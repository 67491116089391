function SearchInput({ handleInput, handleKeydown, isMobile, refFunc, value }) {
  function ensureFocusAtEndOfValue(e) {
    const val = e.target.value;
    e.target.value = "";
    e.target.value = val;
  }

  const placeholderText = isMobile
    ? "Search"
    : "Search for products, advertising, resources, and services…";

  return (
    <input
      className="mtt-hasb-search-input"
      type="text"
      name="SearchableText"
      autoComplete="off"
      onChange={handleInput}
      onKeyDown={handleKeydown}
      onFocus={ensureFocusAtEndOfValue}
      title={placeholderText}
      placeholder={placeholderText}
      ref={refFunc}
      value={value}
    />
  );
}

export default SearchInput;
