import { useCallback, useMemo, useState } from "react";

const defaultSelectedItems = {
  level0SelectedItem: null,
  level1SelectedItem: null,
  level2SelectedItem: null,
};

function useNavProps(nav) {
  const [selectedItems, setSelectedItems] = useState(defaultSelectedItems);
  const [productsCollapseIsExpanded, setProductsCollapseIsExpanded] =
    useState(false);

  const clearSelectedItems = useCallback(
    () => setSelectedItems(defaultSelectedItems),
    []
  );

  const setLevel0SelectedItem = useCallback(item => {
    setSelectedItems({
      level0SelectedItem: item,
      level1SelectedItem: null,
      level2SelectedItem: null,
    });
  }, []);
  const setLevel1SelectedItem = useCallback(
    item => {
      setSelectedItems({
        ...selectedItems,
        level1SelectedItem: item,
        level2SelectedItem: null,
      });
    },
    [selectedItems]
  );
  const setLevel2SelectedItem = useCallback(
    item => {
      setSelectedItems({
        ...selectedItems,
        level2SelectedItem: item,
      });
    },
    [selectedItems]
  );
  const rootLevel = useMemo(
    () => (nav ? { title: "", level: -1, children: nav } : null),
    [nav]
  );

  return {
    clearSelectedItems,
    productsCollapseIsExpanded,
    setProductsCollapseIsExpanded,
    selectedItems,
    setLevel0SelectedItem,
    setLevel1SelectedItem,
    setLevel2SelectedItem,
    rootLevel,
  };
}

export default useNavProps;
