import { useState } from "react";
import NavWindowDesktop from "./NavWindowDesktop.jsx";
import classNames from "classnames";
import useOffClick from "../hooks/useOffClick.js";
import * as h from "../helpers.js";

function NavBar({ isMobile, nav, user }) {
  const [selectedTopLevelItem, setSelectedTopLevelItem] = useState(null);

  const {
    addOffClick: addNavOffClick,
    offClickAddedRef: navOffClickAddedRef,
    removeOffClick: removeNavOffClick,
  } = useOffClick(closeDesktopNav);

  function handleTopLevelItemClick(e, item) {
    if (!navOffClickAddedRef.current) {
      e.preventDefault();
      e.stopPropagation();
      addNavOffClick();
      setSelectedTopLevelItem(item);
    } else if (selectedTopLevelItem.title !== item.title) {
      e.preventDefault();
      e.stopPropagation();
      setSelectedTopLevelItem(item);
    }
  }

  function closeDesktopNav() {
    setSelectedTopLevelItem(null);
    removeNavOffClick();
  }
  function getDesktopNavMaxWidth() {
    if (!nav || nav.length < 1) {
      return "0";
    }
    const itemCount = nav.length;
    const avgItemWidth = 84.5;
    const gapWidth = 150;

    return String(itemCount * avgItemWidth + (itemCount - 1) * gapWidth) + "px";
  }

  const desktopNavMaxWidth = getDesktopNavMaxWidth();
  return (
    <div
      className={classNames("mtt-header-nav-bar", {
        "mtt-hab-mobile": isMobile,
      })}
    >
      <div className="mtt-hnb-nav-items" style={{ width: desktopNavMaxWidth }}>
        {nav
          ? nav.map(navItem => {
              if (h.shouldShowItemToUser(navItem, user)) {
                return (
                  <div
                    className={classNames(
                      "mtt-hnb-nav-item",
                      navItem.review_state,
                      {
                        "mtt-hnb-nav-item-is-selected":
                          navItem.title === selectedTopLevelItem?.title,
                      }
                    )}
                    key={navItem.title + navItem.order_no}
                    onClick={e => handleTopLevelItemClick(e, navItem)}
                  >
                    {navItem.title}
                  </div>
                );
              }
              return null;
            })
          : null}
      </div>
      {!isMobile && selectedTopLevelItem ? (
        <NavWindowDesktop
          selectedTopLevelItem={selectedTopLevelItem}
          user={user}
        />
      ) : null}
    </div>
  );
}

export default NavBar;
