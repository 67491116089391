import { cloneElement, createRef, useMemo } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import NavWindowDesktopItemLevel from "./NavWindowDesktopItemLevel.jsx";
import { useMutation } from "@tanstack/react-query";
import classNames from "classnames";
import * as constants from "../constants.js";
import * as h from "../helpers.js";

function NavDesktop({ user, selectedTopLevelItem }) {
  const canShowDeadLinks = h.getCanShowDeadLinks(user);

  function partitionItemsBasedOnHeight(items) {
    if (!items) {
      return [[], []];
    }
    const [
      totalHeight,
      itemsWithHeight,
      lastIsChildlessPaddingBottomDifference,
    ] = prepareItemsForHeightDivision(items);

    const midpointHeightWhenLastLeftItemHasChildren = totalHeight / 2;
    const midpointHeightWhenLastLeftItemIsChildless =
      (totalHeight + lastIsChildlessPaddingBottomDifference) / 2;
    let totalLeftItemsHeight = 0;
    let totalRightItemsHeight = 0;
    const left = [];
    const right = [];
    let lastItemWasChildless = false;

    for (let item of itemsWithHeight) {
      const currentMidpointHeight = lastItemWasChildless
        ? midpointHeightWhenLastLeftItemIsChildless
        : midpointHeightWhenLastLeftItemHasChildren;
      const currentLeftItemsHeight = lastItemWasChildless
        ? totalLeftItemsHeight + lastIsChildlessPaddingBottomDifference
        : totalLeftItemsHeight;
      if (currentLeftItemsHeight < currentMidpointHeight) {
        left.push(item);
        totalLeftItemsHeight = totalLeftItemsHeight + item.height;
        lastItemWasChildless = !h.hasChildren(item);
      } else {
        right.push(item);
        totalRightItemsHeight = totalRightItemsHeight + item.height;
      }
    }
    if (canShowDeadLinks) {
      totalRightItemsHeight = totalRightItemsHeight + 4.03;
    }

    return [left, right];
  }

  function prepareItemsForHeightDivision(items) {
    const nColumnsOfChildren = 3;
    const withChldrenBaseHeight = 2.06;
    const withChildrenPaddingBottom = 2.7;
    const withChildrenHeightBeforeChildren =
      withChldrenBaseHeight + withChildrenPaddingBottom;

    const childlessBaseHeight = 1.333;
    const childlessPaddingBottom = 0.444;
    const childlessHeight = childlessBaseHeight + childlessPaddingBottom;
    const childlessHeightWhenLast =
      childlessBaseHeight + withChildrenPaddingBottom;

    const childBaseHeight = 1.22;
    const childrenHeightPerLine = childBaseHeight + childlessPaddingBottom;

    const lastIsChildlessPaddingBottomDifference =
      withChildrenPaddingBottom - childlessPaddingBottom;

    let totalHeight = 0;
    const nItems = items.length;
    const itemsWithHeight = items.reduce(
      (filteredAndWithHeight, item, index) => {
        if (!h.shouldShowItemToUser(item, user)) {
          return filteredAndWithHeight;
        }

        const nVisibleChildren = h.getVisibleChildren(item, user).length;
        // children are in two columns so we count the number of lines of children, 2 children per line
        const childrenHeight =
          Math.ceil(nVisibleChildren / nColumnsOfChildren) *
          childrenHeightPerLine;
        const isLast = index + 1 === nItems;

        const itemHeight =
          nVisibleChildren > 0
            ? withChildrenHeightBeforeChildren + childrenHeight
            : isLast && !canShowDeadLinks
            ? childlessHeightWhenLast
            : childlessHeight;

        totalHeight = totalHeight + itemHeight;
        filteredAndWithHeight.push({ ...item, height: itemHeight });
        return filteredAndWithHeight;
      },
      []
    );

    // include height of Admin: Show Dead Links item, when present
    if (canShowDeadLinks) {
      totalHeight = totalHeight + childlessHeightWhenLast;
    }

    return [
      totalHeight,
      itemsWithHeight,
      lastIsChildlessPaddingBottomDifference,
    ];
  }

  const [selectedItemChildrenColLeft, selectedItemChildrenColRight] =
    partitionItemsBasedOnHeight(selectedTopLevelItem?.children);

  const showDeadLinks = user.show_dead_links;
  const toggleShowDeadLinksMutation = useMutation([
    constants.toggleShowDeadLinksMutationName,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const transitionNodeRef = useMemo(() => createRef(), [selectedTopLevelItem]);
  const transitionKey =
    selectedTopLevelItem.title + selectedTopLevelItem.order_no;

  return (
    <TransitionGroup
      appear={true}
      exit={true}
      component={null}
      childFactory={child =>
        cloneElement(child, {
          classNames: "mtt-nav-fade",
        })
      }
    >
      <CSSTransition
        key={transitionKey}
        timeout={{ enter: 200, exit: 150 }}
        classNames="mtt-nav-fade"
        nodeRef={transitionNodeRef}
      >
        <div
          className="mtt-hnb-desktop-nav"
          ref={transitionNodeRef}
          onClick={h.captureClick}
        >
          <div className="mtt-hnb-dn-col mtt-hnb-dn-col-left">
            {selectedItemChildrenColLeft.map(item => (
              <NavWindowDesktopItemLevel
                clickableHeaders={showDeadLinks}
                item={item}
                key={item.title + item.order_no}
                user={user}
              />
            ))}
          </div>
          <div className="mtt-hnb-dn-col mtt-hnb-dn-col-right">
            {selectedItemChildrenColRight.map(item => (
              <NavWindowDesktopItemLevel
                clickableHeaders={showDeadLinks}
                item={item}
                key={item.title + item.order_no}
                user={user}
              />
            ))}
            {canShowDeadLinks ? (
              <div
                className={classNames(
                  "mtt-hnb-dn-second-level-item mtt-hnb-second-level-item-admin-link"
                )}
              >
                <h3>
                  <a
                    onClick={e => {
                      e.preventDefault();
                      if (!toggleShowDeadLinksMutation.isLoading) {
                        toggleShowDeadLinksMutation.mutate(
                          user.show_dead_links
                        );
                      }
                    }}
                    href={`${
                      user.show_dead_links ? "hide" : "show"
                    }-inactive-links`}
                  >
                    {`Admin: ${
                      user.show_dead_links ? "Hide" : "Show"
                    } inactive links`}
                  </a>
                </h3>
              </div>
            ) : null}
          </div>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default NavDesktop;
