import { useEffect, useState } from "react";

function useBreakpoint(breakpointQuery) {
  const [breakpointPassed, setBreakpointPassed] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(breakpointQuery);
    function updateBreakpointPassed() {
      setBreakpointPassed(mediaQuery.matches);
    }
    updateBreakpointPassed();
    try {
      mediaQuery.addEventListener("change", updateBreakpointPassed);
    } catch (e1) {
      try {
        // To support older versions of Safari < 14
        mediaQuery.addListener(updateBreakpointPassed);
      } catch (e2) {
        console.error(e2);
      }
    }
    return () => {
      try {
        mediaQuery.removeEventListener("change", updateBreakpointPassed);
      } catch (e1) {
        try {
          // To support older versions of Safari < 14
          mediaQuery.removeListener(updateBreakpointPassed);
        } catch (e2) {
          console.error(e2);
        }
      }
    };
  }, [breakpointQuery]);

  return breakpointPassed;
}

export default useBreakpoint;
