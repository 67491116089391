import { useQuery } from "@tanstack/react-query";
import axiosMaker from "axios";
import * as constants from "../constants";

const axios = axiosMaker.create();
axios.defaults.withCredentials = true;

const getNavCacheBusted = () => {
  if (process.env.NODE_ENV === "development" && !constants.isPloneDev) {
    // return axios.get("data/nav-full.json");
    return axios.get("data/nav-employee-new.json");
  }
  return axios.get(constants.getNavEndpoint, {
    params: { d: String(Date.now()), currentpath: window.location.pathname },
  });
};

function useNav() {
  const navQuery = useQuery(
    [constants.getNavEndpoint],
    function ({ queryKey }) {
      return axios
        .get(queryKey[0], { params: { currentpath: window.location.pathname } })
        .then(res => {
          // When the user logs out, sometimes the nav is cached as an empty array
          // so when we receive an empty array, retry once with cache buster query
          if (res.data?.length === 0) {
            return getNavCacheBusted();
          } else {
            return res;
          }
        })
        .then(
          res => res.data,
          err => {
            console.log("In useNav, err:", err);
            return err;
          }
        );
    }
  );
  return navQuery;
}

export default useNav;
