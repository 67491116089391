import classNames from "classnames";

function NavDesktopItem({ handleClick, isLevelHeader, item, showDeadLinks }) {
  return (
    <li
      className={classNames("mtt-fnd-desktop-item", item.review_state, {
        "mtt-fnd-desktop-item-hidden":
          !showDeadLinks && item.review_state === "private",
      })}
      onClick={e => {
        handleClick(item, e);
      }}
    >
      {isLevelHeader ? item.title : <a href={item.url}>{item.title}</a>}
    </li>
  );
}

export default NavDesktopItem;
