import React, { Component } from "react";
import axios from "axios";

class ArticleLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: "",
    };
  }

  componentDidMount() {
    axios
      .get(`${this.props.articlePath}/json-view`, { withCredentials: true })
      .then(
        res => {
          this.setState({
            article: res.data.body,
          });
        },
        err => {
          console.log("In articleLoader, err:", err);
        }
      );
  }

  componentDidUpdate() {
    window.setupMTSSOs();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Don't update unless article or articlePath change
    return (
      nextState.article !== this.state.article ||
      nextProps.articlePath !== this.props.articlePath
    );
  }

  render() {
    return <div dangerouslySetInnerHTML={{ __html: this.state.article }} />;
  }
}

export default ArticleLoader;
