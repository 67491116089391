import { forwardRef } from "react";
import { useMutation } from "@tanstack/react-query";
import NavLevelCollapseSection from "./NavLevelCollapseSection";
import caret from "../icons/Caret arrow icon - white.svg";
import classNames from "classnames";
import * as constants from "../constants";
import * as h from "../helpers";
import "./navLevel.css";

const NavLevel = forwardRef(
  (
    {
      handleSelectedItemClick,
      isMobile,
      level,
      productsCollapseIsExpanded,
      setProductsCollapseIsExpanded,
      selectedItem,
      setSelectedItem,
      user,
    },
    ref
  ) => {
    const isInnerLevel = !!level?.title;

    const toggleShowDeadLinksMutation = useMutation([
      constants.toggleShowDeadLinksMutationName,
    ]);

    const showDeadLinks = h.getShowDeadLinks(user);

    function captureClick(e) {
      e.stopPropagation();
    }

    function handleItemClick(item, e) {
      const isSelected = selectedItem?.title === item.title;
      const goToUrl = isSelected && showDeadLinks;
      if (h.hasChildren(item) && !goToUrl) {
        setSelectedItem(item);
      } else if (item.new_window) {
        e.preventDefault();
        h.openNewWindowIfPossible(item.url);
      } else {
        window.location.assign(item.url);
      }
    }

    return (
      <ul className="mtt-nav-level" onClick={captureClick} ref={ref}>
        {isInnerLevel ? (
          isMobile ? (
            <li
              className="mtt-nl-item mtt-nl-category"
              onClick={handleSelectedItemClick}
            >
              {level.title}
            </li>
          ) : (
            <li className="mtt-nl-item mtt-nl-category">{level.title}</li>
          )
        ) : null}
        {level.children.map(item => {
          const isSelected = selectedItem?.title === item.title;
          const noneSelected = !selectedItem;
          const hasChildren = h.hasChildren(item);
          const hasCollapse = item.level === 0 && item.title === "Products";

          return (
            <li
              className={classNames("mtt-nl-item", item.review_state, {
                "mtt-nl-item-root-level": !isInnerLevel,
                "mtt-nl-item-none-selected": noneSelected,
                "mtt-nl-item-selected": isSelected,
                "mtt-nl-item-hidden":
                  !showDeadLinks && item.review_state === "private",
                "mtt-nl-item-with-collapse": hasCollapse,
                "mtt-nl-item-with-collapse-expanded":
                  hasCollapse && productsCollapseIsExpanded,
              })}
              onClick={hasCollapse ? undefined : e => handleItemClick(item, e)}
              key={item.title + item.level + item.order_no}
            >
              {hasCollapse ? (
                <>
                  <div
                    onClick={() =>
                      setProductsCollapseIsExpanded(!productsCollapseIsExpanded)
                    }
                    className="clickable"
                  >
                    {item.title}
                  </div>
                  <NavLevelCollapseSection
                    expanded={productsCollapseIsExpanded}
                    items={item.children}
                    showDeadLinks={showDeadLinks}
                    handleItemClick={handleItemClick}
                  />
                  <div
                    className="mtt-nl-collapse-control"
                    onClick={() =>
                      setProductsCollapseIsExpanded(!productsCollapseIsExpanded)
                    }
                  >
                    {`${productsCollapseIsExpanded ? "Hide" : "See"} all`}
                    <img
                      src={caret}
                      alt={
                        "arrow " + productsCollapseIsExpanded ? "up" : "down"
                      }
                      className={classNames("mtt-nl-cc-arrow", {
                        "mtt-nl-cc-arrow-up": productsCollapseIsExpanded,
                      })}
                    />
                  </div>
                </>
              ) : hasChildren ? (
                item.title
              ) : (
                <a
                  href={item.url}
                  target={item.new_window ? "_blank" : "_self"}
                  rel="noreferrer"
                >
                  {item.title}
                </a>
              )}
              {hasChildren && !hasCollapse ? <img src={caret} alt="" /> : null}
            </li>
          );
        })}
        {!isInnerLevel && h.getCanShowDeadLinks(user) ? (
          <li
            className="mtt-nl-show-links-item"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              if (!toggleShowDeadLinksMutation.isLoading) {
                toggleShowDeadLinksMutation.mutate(showDeadLinks);
              }
            }}
          >
            {`Admin: ${showDeadLinks ? "Hide" : "Show"} inactive links`}
          </li>
        ) : null}
      </ul>
    );
  }
);

export default NavLevel;
