import { useEffect, useState } from "react";
import NavBarDesktop from "./NavBarDesktop";
import NavMobileWrapper from "./NavMobileWrapper";
import ActionsAndStatusBar from "./ActionsAndStatusBar";
import useBreakpoint from "../hooks/useBreakpoint";
import useUser from "../hooks/useUser";
import useNav from "../hooks/useNav";
import useOffClick from "../hooks/useOffClick";
import classNames from "classnames";
import * as constants from "../constants";
import * as h from "../helpers";
import "./nav.css";

function Header() {
  const isMobile = useBreakpoint(constants.mobileBreakpointQuery);
  const readyForInitialRender = isMobile !== null;
  return readyForInitialRender ? <HeaderContent isMobile={isMobile} /> : null;
}

function HeaderContent({ isMobile }) {
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);
  const { data: nav } = useNav();
  const { data: user } = useUser();
  const edgeStatus = h.getEdgeStatusFromUser(user);

  const {
    addOffClick: addMobileNavOffClick,
    offClickAddedRef: mobileNavOffClickAddedRef,
    removeOffClick: removeNavOffClick,
  } = useOffClick(closeMobileNav);

  function closeMobileNav(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    setMobileNavIsOpen(false);
    removeNavOffClick();
  }

  function openMobileNav(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!mobileNavOffClickAddedRef.current) {
      addMobileNavOffClick();
    }
    setMobileNavIsOpen(true);
  }

  useEffect(() => {
    if (!isMobile && mobileNavIsOpen) {
      setMobileNavIsOpen(false);
      removeNavOffClick();
    }
  }, [isMobile, mobileNavIsOpen, removeNavOffClick]);

  return (
    <div className={classNames({ "mtt-mobile": isMobile })}>
      <ActionsAndStatusBar
        edgeStatus={edgeStatus}
        isMobile={isMobile}
        isEdge={!!edgeStatus}
        nav={nav}
        openMobileNav={openMobileNav}
        user={user}
      />
      {/* <StatusBar edgeStatus={edgeStatus} isMobile={isMobile} user={user} /> */}
      {isMobile ? (
        <NavMobileWrapper
          closeNav={closeMobileNav}
          edgeStatus={edgeStatus}
          isOpen={mobileNavIsOpen}
          user={user}
          nav={nav}
        />
      ) : (
        <NavBarDesktop isMobile={isMobile} user={user} nav={nav} />
      )}
    </div>
  );
}

export default Header;
