import React, { useState } from "react";
import NavDesktopItem from "./NavDesktopItem";
import NavDesktopLevel from "./NavDesktopLevel";
import * as h from "../helpers";

function NavDesktopItemLevelPair({ item, levelIndex, showDeadLinks }) {
  const [expanded, setExpanded] = useState(false);
  const canHaveAnotherLevel = levelIndex < 3;
  const isLevelHeader = canHaveAnotherLevel && h.hasChildren(item);

  function handleItemClick(item, e) {
    if (isLevelHeader) {
      setExpanded(!expanded);
    } else if (item.new_window) {
      e.preventDefault();
      h.openNewWindowIfPossible(item.url);
    } else {
      window.location.assign(item.url);
    }
  }

  return (
    <React.Fragment>
      <NavDesktopItem
        isLevelHeader={isLevelHeader}
        item={item}
        showDeadLinks={showDeadLinks}
        handleClick={handleItemClick}
      />
      {isLevelHeader ? (
        <NavDesktopLevel
          expanded={expanded}
          level={item.children}
          levelIndex={levelIndex + 1}
          showDeadLinks={showDeadLinks}
        />
      ) : null}
    </React.Fragment>
  );
}

export default NavDesktopItemLevelPair;
