import React from "react";
import SmoothCollapse from "react-smooth-collapse";
import caret from "../icons/Caret arrow icon - white.svg";
import classNames from "classnames";
import * as h from "../helpers";

function NavLevelCollapseSection({
  expanded,
  handleItemClick,
  items,
  showDeadLinks,
}) {
  return (
    <SmoothCollapse expanded={expanded} heightTransition="0.3s ease">
      <ul className="mtt-nav-level mtt-nav-level-in-collapse">
        {items.map(item => {
          const hasChildren = h.hasChildren(item);
          return (
            <li
              className={classNames(
                "mtt-nl-item mtt-nl-item-in-collapse",
                item.review_state,
                {
                  "mtt-nl-item-hidden":
                    !showDeadLinks && item.review_state === "private",
                }
              )}
              onClick={e => handleItemClick(item, e)}
              key={item.title + item.level + item.order_no}
            >
              {hasChildren ? (
                item.title
              ) : (
                <a
                  href={item.url}
                  target={item.new_window ? "_blank" : "_self"}
                  rel="noreferrer"
                >
                  {item.title}
                </a>
              )}
              {hasChildren ? <img src={caret} alt="" /> : null}
            </li>
          );
        })}
      </ul>
    </SmoothCollapse>
  );
}

export default NavLevelCollapseSection;
