import { cloneElement, createRef, useMemo, useRef, useState } from "react";
import NavLevel from "./NavLevel";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import classNames from "classnames";
import backArrow from "../icons/Caret arrow icon - red.svg";
import * as h from "../helpers";

function NavMobile({
  clearSelectedItems,
  isOpen,
  isFooter,
  productsCollapseIsExpanded,
  setProductsCollapseIsExpanded,
  rootLevel,
  selectedItems,
  setLevel0SelectedItem,
  setLevel1SelectedItem,
  setLevel2SelectedItem,
  user,
}) {
  const [transition, setTransition] = useState("left");
  const navContainerRef = useRef();

  function captureClick(e) {
    e.stopPropagation();
  }

  function handleSelectedItemClick() {
    goBackALevel();
  }

  function goBackALevel() {
    const newSelected = getSecondDeepestSelectedItem();
    setSelectedItem(newSelected);
  }

  function getCurrentLevel() {
    return getDeepestSelectedItem().level;
  }

  function getDeepestSelectedItem() {
    const deepest = selectedItems.level2SelectedItem
      ? selectedItems.level2SelectedItem
      : selectedItems.level1SelectedItem
      ? selectedItems.level1SelectedItem
      : selectedItems.level0SelectedItem
      ? selectedItems.level0SelectedItem
      : rootLevel;
    return deepest;
  }

  function getSecondDeepestSelectedItem() {
    const orderedSelectedItems = [
      selectedItems.level2SelectedItem,
      selectedItems.level1SelectedItem,
      selectedItems.level0SelectedItem,
    ];
    let deepestSelectedItem = null;
    let secondDeepest = rootLevel;
    for (const item of orderedSelectedItems) {
      if (item) {
        if (deepestSelectedItem) {
          secondDeepest = item;
          break;
        }
        deepestSelectedItem = item;
      }
    }
    return secondDeepest;
  }

  function setSelectedItem(newSelected) {
    const currentLevel = getCurrentLevel();
    const toLevel = newSelected.level;
    const transition = currentLevel > toLevel ? "right" : "left";

    setTransition(transition);

    switch (toLevel) {
      case -1:
        clearSelectedItems();
        break;
      case 0:
        setLevel0SelectedItem(newSelected);
        break;
      case 1:
        setLevel1SelectedItem(newSelected);
        break;
      case 2:
        setLevel2SelectedItem(newSelected);
        break;
      default:
        return;
    }
  }

  const activeItem = getDeepestSelectedItem();
  const isInnerLevel = !!activeItem?.title;
  const transitionKey = activeItem?.title + activeItem?.level;
  const transitionName = `mtt-nav-slide-${transition}`;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const transitionNodeRef = useMemo(() => createRef(), [activeItem]);

  const activeLevelHeight = useMemo(() => {
    if (!activeItem) return "0";
    // these heights are based on what is set in css on .mtt-nl-item and other classes
    const itemHeightInRem = 1.498;
    const spaceBetweenItemsInRem = 2.44;
    const innerLevelTitleHeightInRem = 2.063;
    const addedHeightForInnerLevelTitle =
      innerLevelTitleHeightInRem + spaceBetweenItemsInRem;
    const itemWithCollapseClosedContainerHeightInRem = 3.207;
    const itemWithCollapseExpandedContainerHeightInRem = 4.207;
    const adminShowHiddenItemsHeight = h.getCanShowDeadLinks(user) ? 3.994 : 0;

    const shownActiveLevelItems = activeItem.children?.filter(item =>
      h.shouldShowItemToUser(item, user)
    );

    const itemWithCollapse = shownActiveLevelItems?.find(item =>
      h.getHasCollapse(item)
    );

    function calculateBasicItemsHeight(nItems) {
      return nItems * itemHeightInRem + (nItems - 1) * spaceBetweenItemsInRem;
    }

    let itemsHeightWithoutTitle;
    if (itemWithCollapse) {
      const nBasicItems = shownActiveLevelItems.length - 1;
      const basicItemsHeight = calculateBasicItemsHeight(nBasicItems);
      const collapseItemHeight = productsCollapseIsExpanded
        ? itemWithCollapseExpandedContainerHeightInRem +
          calculateBasicItemsHeight(itemWithCollapse.children.length)
        : itemWithCollapseClosedContainerHeightInRem;
      itemsHeightWithoutTitle = basicItemsHeight + collapseItemHeight;
    } else {
      itemsHeightWithoutTitle = calculateBasicItemsHeight(
        shownActiveLevelItems.length
      );
    }

    const heightInRem = isInnerLevel
      ? itemsHeightWithoutTitle + addedHeightForInnerLevelTitle
      : itemsHeightWithoutTitle + adminShowHiddenItemsHeight;
    return heightInRem + "rem";
  }, [activeItem, isInnerLevel, productsCollapseIsExpanded, user]);

  return (
    <div
      className={classNames({
        "mtt-nav-container-mobile": !isFooter,
        "mtt-nav-container-mobile-open": !isFooter && isOpen,
        "mtt-footer-nav-container-mobile": isFooter,
      })}
      onClick={captureClick}
      ref={navContainerRef}
    >
      <div className="mtt-nav-levels-container">
        <div
          className={classNames("mtt-nlc-back-container", {
            clickable: isInnerLevel,
          })}
          role={isInnerLevel ? "button" : ""}
          onClick={isInnerLevel ? goBackALevel : null}
        >
          {isInnerLevel ? (
            <>
              <img
                src={backArrow}
                alt="Back Arrow"
                className="mtt-nlc-bc-arrow"
              />
              Back to{" "}
              {getCurrentLevel() !== 0 &&
              // ensure fallback to 'Main Menu' when product item is selected from root level,
              (selectedItems.level0SelectedItem !== null ||
                getCurrentLevel() > 1)
                ? getSecondDeepestSelectedItem().title
                : "Main Menu"}
            </>
          ) : null}
        </div>
        <div
          className={classNames("mtt-nav-level-container-mobile")}
          // setting a minHeight ensures vertical space for the incoming level when it is taller than the previous level
          style={{
            minHeight: activeLevelHeight,
          }}
        >
          <TransitionGroup
            exit={true}
            component={null}
            childFactory={child =>
              cloneElement(child, {
                classNames: transitionName,
              })
            }
          >
            <CSSTransition
              key={transitionKey}
              timeout={225}
              classNames={transitionName}
              nodeRef={transitionNodeRef}
            >
              {activeItem ? (
                <NavLevel
                  ref={transitionNodeRef}
                  handleSelectedItemClick={handleSelectedItemClick}
                  isMobile={true}
                  level={activeItem}
                  productsCollapseIsExpanded={productsCollapseIsExpanded}
                  setProductsCollapseIsExpanded={setProductsCollapseIsExpanded}
                  setSelectedItem={setSelectedItem}
                  user={user}
                />
              ) : (
                <div ref={transitionNodeRef}></div>
              )}
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
}

export default NavMobile;
