import { useCallback, useRef, useState } from "react";

function useOffClick(handler) {
  const [offClickAdded, setOffClickAdded] = useState(false);

  // we make a ref available so that an up to date value can be available in callbacks
  const offClickAddedRef = useRef(false);

  // we trade off the ability to update the handler passed in for stability of the handler —
  // only the first handler that this hook receives will be used
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlerCB = useCallback(handler, []);

  const handleKeyDown = useCallback(
    e => {
      if (e.key === "Escape") {
        e.stopPropagation();
        handlerCB();
      }
    },
    [handlerCB]
  );

  const addOffClick = useCallback(() => {
    // only add "off" click handler if it hasn't been added yet
    if (offClickAddedRef.current === false) {
      document.addEventListener("click", handlerCB);
      document.addEventListener("keydown", handleKeyDown);
      offClickAddedRef.current = true;
      setOffClickAdded(true);
    }
  }, [handlerCB, handleKeyDown]);

  const removeOffClick = useCallback(() => {
    document.removeEventListener("click", handlerCB);
    document.removeEventListener("keydown", handleKeyDown);
    offClickAddedRef.current = false;
    setOffClickAdded(false);
  }, [handlerCB, handleKeyDown]);

  return { offClickAdded, offClickAddedRef, addOffClick, removeOffClick };
}

export default useOffClick;
