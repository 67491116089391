import edgePreferred from "./icons/Edge HL - Flag Preferred Member.svg";
import edgeSelect from "./icons/Edge HL - Flag Select Member.svg";
import edgePremier from "./icons/Edge HL - Flag Premier Member.svg";
import edgePreferredWhite from "./icons/Edge WL - Flag Preferred.svg";
import edgeSelectWhite from "./icons/Edge WL - Flag Select.svg";
import edgePremierWhite from "./icons/Edge WL - Flag Premier.svg";

export const assetPrefix = process.env.REACT_APP_ASSET_PREFIX;
export const getNavEndpoint = process.env.REACT_APP_GET_NAV_ENDPOINT;
export const getUserEndpoint = process.env.REACT_APP_GET_USER_ENDPOINT;
export const isPloneDev =
  typeof process.env.REACT_APP_IS_PLONE === "string" &&
  process.env.REACT_APP_IS_PLONE.toLowerCase() === "true";
export const searchEndpoint = process.env.REACT_APP_SEARCH_ENDPOINT;
export const setShowDeadLinksEndpoint =
  process.env.REACT_APP_SET_SHOW_DEAD_LINKS_ENDPOINT;
export const MTLogoUrl =
  process.env.REACT_APP_ENV === "universal"
    ? process.env.REACT_APP_SITE_ROOT
    : "/";
const edgeUrlEnding = "/edge";
const FAQUrlEnding = "/mtp/faqs";
export const edgeUrl =
  process.env.REACT_APP_ENV === "universal"
    ? process.env.REACT_APP_SITE_ROOT + edgeUrlEnding
    : edgeUrlEnding;
export const FAQUrl =
  process.env.REACT_APP_ENV === "universal"
    ? process.env.REACT_APP_SITE_ROOT + FAQUrlEnding
    : FAQUrlEnding;
export const minimumSearchLength = 2;
export const mobileBreakpointQuery = "(max-width: 1200px)";
export const searchResultsLimit = 12;
export const toggleShowDeadLinksMutationName = "toggleShowDeadLinks";

export const edgeStatuses = [
  {
    prop_name: "edge_preferred",
    name: "Preferred",
    whiteIcon: edgePreferredWhite,
    colorIcon: edgePreferred,
  },
  {
    prop_name: "edge_premier",
    name: "Premier",
    whiteIcon: edgePremierWhite,
    colorIcon: edgePremier,
  },
  {
    prop_name: "edge_select",
    name: "Select",
    whiteIcon: edgeSelectWhite,
    colorIcon: edgeSelect,
  },
];
