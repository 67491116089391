import React from "react";
import classNames from "classnames";
import * as h from "../helpers.js";

function NavWindowDesktopItemLevel({ clickableHeaders, item, user }) {
  const hasVisibleChildren = h.hasVisibleChildren(item, user);

  function handleItemClick(e) {
    if (item.new_window) {
      e.preventDefault();
      h.openNewWindowIfPossible(item.url);
    } else {
      window.location.assign(item.url);
    }
  }

  return (
    <div
      className={classNames("mtt-hnb-dn-second-level-item", item.review_state, {
        "mtt-hnb-dn-second-level-item-has-children": hasVisibleChildren,
      })}
    >
      <h3>
        {hasVisibleChildren && !clickableHeaders ? (
          item.title
        ) : (
          <a href={item.url} onClick={handleItemClick}>
            {item.title}
          </a>
        )}
      </h3>
      {hasVisibleChildren ? (
        <div className="mtt-hnb-dn-third-level-items mtt-hnb-dn-third-level-items-wide">
          {item.children.map(child => {
            return h.shouldShowItemToUser(child, user) ? (
              <div
                className={classNames(
                  "mtt-hnb-dn-tli-item",
                  child.review_state
                )}
              >
                <a href={child.url} onClick={handleItemClick}>
                  {child.title}
                </a>
                <div className="mtt-hnb-dn-tli-item-spacer"></div>
              </div>
            ) : null;
          })}
        </div>
      ) : null}
    </div>
  );
}

export default NavWindowDesktopItemLevel;
